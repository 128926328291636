<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-body printableArea">
        <h3>
          <b>Daftar Drafting Dibatalkan</b>
        </h3>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :filter="filters"
              :header="headers"
              :onAction="get"
              :onDetail="doDetail"
              @onDelete="doDelete"
              :overideSearch="search"
              @onSearch="onSearch"
              :forbidDetail="forbidRead"
              :forbidDelete="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";
import axios from "axios";
export default {
  components: {
    RotateSquare5,
    Table,
  },
  data() {
    let _this = this;
    return {
      documentNameList: [],
      collapse: true,
      form: {
        document_name: null,
      },
      headers: {
        no_opd: {
          label: "Nomor Surat",
          data: "no_opd",
        },
        subject: {
          label: "Perihal",
          data: "subject",
        },
        document_date: {
          label: "Tanggal",
          data: "document_date",
          render: function (data) {
            return new Date(data).toDateString();
          },
        },
        // document_number:{
        //   label:'Nomor Surat',
        //   data:'document_number',
        // },
        penandatangan: {
          label: "Penanda Tangan",
          data: "penandatangan",
        },
        drafter: {
          label: "Drafter",
          data: "drafter",
        },
        status_approve: {
          label: "Status",
          data: "status_approve",
        },
        // status_approve: {
        //   label: "Status",
        //   data: "final_approvement.name",
        //   render: function (data) {
        //     return _this.$t(data);
        //   },
        // },
      },
    };
  },
  computed: {
    items() {
      let data = [];
      if (this.$store.state.drafting_file_declined.items !== undefined) {
        if (
          this.$store.state.drafting_file_declined.items.items !== undefined
        ) {
          if (
            !Array.isArray(
              this.$store.state.drafting_file_declined.items.items
            ) &&
            this.$store.state.drafting_file_declined.items.items !== undefined
          ) {
            data = Object.keys(
              this.$store.state.drafting_file_declined.items.items
            ).map(
              (i) => this.$store.state.drafting_file_declined.items.items[i]
            );
          } else {
            data = this.$store.state.drafting_file_declined.items.items;
          }
        }
      }
      return data;
    },
    filters() {
      let filt = {};
      if (this.$store.state.drafting_file_declined.items !== undefined) {
        if (
          this.$store.state.drafting_file_declined.items.filters !== undefined
        ) {
          filt = this.$store.state.drafting_file_declined.items.filters;
        }
      }
      return filt;
    },
    state() {
      return this.$store.state.drafting_file_declined;
    },
    mounted() {
      const state = {
        loaded: false,
        isAdd: false,
        isEdit: false,
        isDetail: false,
      };
      this.$store.commit("drafting_file_declined/STATE", state);
    },
    forbidUpdate() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return !this.$store.state.profile.permissions.drafting_approved.update;
      }
      return false;
    },
    canSeeAll() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return this.$store.state.profile.permissions.drafting_approved.read;
      }
      return false;
    },
    forbidRead() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return !this.$store.state.profile.permissions.drafting_approved.read;
      }
      return false;
    },
    forbidAtasan() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return !this.$store.state.profile.permissions.drafting_approved.read;
      }
      return false;
    },
    forbidKonfirmasi() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return this.$store.state.profile.permissions.drafting_approved.create;
      }
      return false;
    },
    forbidDel() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return !this.$store.state.profile.permissions.drafting_approved.delete;
      }
      return false;
    },
    forbidDisposisi() {
      if (this.$store.state.profile.permissions.drafting_approved) {
        return this.$store.state.profile.permissions.drafting_approved.create;
      }
      return false;
    },
  },
  mounted() {
    const state = {
      loaded: false,
      items: [],
      search: this.search,
      from: this.from,
      jabatan: this.$store.state.profile.user.structural_position_id,
      // jabatan: this.$store.state.documentIn.items.atensi,
      start_date: this.start_date,
      end_date: this.end_date,
    };
    this.$store.commit("drafting_file_declined/STATE", state);
    this.get(state);
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    goBack() {
      this.$router.push("/drafting_file_declined");
    },
    handleShowFilter() {
      this.showFilter = !this.showFilter;
    },
    changeDt() {
      // this.$children[0].$refs.table.reload();
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        cari: this.cari,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("drafting_file_declined/STATE", state);
      this.get(state);
    },
    get(val) {
      this.$store.dispatch("drafting_file_declined/getdrafting", val);
    },
    resetInput() {
      this.start_date = moment().startOf("year").format("YYYY-MM-DD");
      this.end_date = moment().endOf("year").format("YYYY-MM-DD");
      this.cari = "";
      // this.nomor_agenda = "";
      // this.periode_surat = "";
      // this.perihal = "";
      // this.nomor_surat = "";
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("drafting_approved/STATE", state);
      this.get(state);
      // this.$children[0].$refs.table.reload();
    },
    filterData: function () {
      const state = {
        loaded: false,
        items: [],
        from: this.from,
        search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.$store.commit("drafting_approved/STATE", state);
      this.get(state);
    },
    doDetail(val) {
      this.$store.dispatch("drafting_file_approved/onDetail", val);
    },
    // doDetailTL(val) {
    //   this.$store.dispatch("drafting_file_approved/onDetailTL", val);
    // },
    // doBerkas(val) {
    //   const data = {
    //     id: val.id,
    //     flag_atasan: 1,
    //   };
    //   // const data = JSON.stringify(payload);
    //   this.$store.dispatch("documentIn/submitTandaiAtasan", data);
    // },
    doAtasan(val) {
      const data = {
        id: val.id,
        flag_atasan: 1,
      };
      // const data = JSON.stringify(payload);
      this.$store.dispatch("drafting_approved/submitTandaiAtasanDepan", data);
    },
    doEdit(val) {
      this.$store.dispatch("drafting_approved/onEdit", val);
    },
    doKonfirmasi(val) {
      this.$store.dispatch("drafting_approved/onKonfirmasi", val);
    },
    // doKonfirmasi(val) {
    //   this.$store.dispatch("documentIn/onConfirm", val);
    // },
    doDisposisi(val) {
      this.$store.dispatch("drafting_approved/onDisposisi", val);
    },
    doDelete(val) {
      this.$store.dispatch("drafting_approved/submitDelete", val);
    },
    getFrom() {
      const state = {
        search: this.search,
        from: this.from,
        // loaded: false,
        // items: [],
        // from: this.from,
        // search: this.search,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      if (this.from == "pencarian_khusus") {
        this.showFilter = !this.showFilter;
      }
      this.$store.commit("drafting_approved/STATE", state);
      this.get(state);
    },
    // setValue(model, data) {
    //   this.form[model] = data;
    // },
    // documentName(query) {
    //   axios.get(`clasification/list-active?s=${query}`).then((res) => {
    //     this.documentNameList = res.data.items;
    //   });
    // },
    // doDetail(val) {
    //   // this.$store.dispatch("drafting_file_declined/onDetail", val);
    //   const idsurat = val.id;
    //   let route = this.$router.resolve({ path: "/drafting-file/detail" });
    //   let route1 = `${route.href}/${idsurat}`;
    //   window.open(route1, "_blank");
    // },
    // doEdit(val) {
    //   this.$store.dispatch("drafting_file_declined/onEdit", val);
    // },
    // doDelete(val) {
    //   this.$store.dispatch("drafting_file_declined/onDelete", val);
    // },
  },
};
</script>
